@import url(https://fonts.googleapis.com/css2?family=IM+Fell+English+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inknut+Antiqua&display=swap);
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto Mono", sans-serif;
    /* IE, only works on <img> tags */
    -ms-interpolation-mode: nearest-neighbor;
    /* Firefox */
    image-rendering: crisp-edges;
    /* Chromium + Safari */
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* FireFirefox */
}

    *::-webkit-scrollbar {
        display: none;
    }

iframe {
    display: "none";
}

.Candy {
    background-color: rgb(32,39,32);
    width: 36px;
    height: 36px !important;
    position: relative;
    overflow: visible;
    padding: 8px;
    border-radius: 12px;
    display: block;
}

    .Candy:hover {
        background-color: #fbb954;
        color: rgb(32,39,32);
    }

.imgheader {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    min-height: 500px;
    max-height: 500px;
    max-width: 1440px;
    width: 100%;
}
.new {
color: #fbb954 !important;
font-weight: 900 !important;
}

body {
    background-color: rgb(32, 39, 32);
    margin: 0px !important;
}

.main {
    padding: 0 !important;
    margin: 0 !important;
}

#scene_02 {
    display: grid !important;
}

#home {
    min-height: 1200px;
}

#Ost {
    width: 105px;
    height: 99px;
    margin-left: auto;
    margin-right: auto;
}

.tape button {
    max-width: 105px;
    min-height: 99px;
    overflow: hidden;
    z-index: 10;
    margin: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
}

.tape img {
    display: block;
    height: 99px !important;
    margin-left: -50px;
    mix-blend-mode: lighten;
}

    .tape img:hover {
        margin-left: -260px;
        cursor: pointer;
    }

.tape span {
    font-size: 10px;
    color: yellow;
    text-align: center;
    margin-top: -75px;
    position: absolute;
}

.container {
    font-size: 16px;
    text-align: center;
    color: #2e879e;
    width: 500px;
    margin: 20px auto;
    transition-duration: 0.3s;
    display: grid;
}

.question {
    text-align: left !important;
    transition-duration: 1s;
    border-bottom: solid 2px #2e879e;
    padding: 10px;
    color: #fbb954;
}

#home p:first-letter {
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    color: #72b6cf;
}

#home p::before, ::after {
    font-size: 16px;
    line-height: 0px;
    color: #72b6cf;
}

#home p::after {
    content: "\201D";
    font-family: "Inknut Antiqua";
    font-weight: bold;
}

#home p::before {
    content: "\201C F";
    font-family: "Inknut Antiqua";
    margin-left: -20px;
    margin-right: -5px;
    font-weight: bold;
}

#nfttext p::after {
    content: "\A\A-MythPunk, a Pre-Industrial and Alchemist Society; \A-SteamPunk, a Gaslamp Victorian Horror Gear Society; \A-DieselPunk, a Noir WWII, Occultist Society;\A-AtomPunk, a Sci-fi Nuclear Power Space Travel Society;\A-CyberPunk, a Dystopian Megacorps and Hacker Future; \A\A As a kind of Season 1, the narrative development will depart from the AtomPunk Realm, creating links with the other Realms throughout the project.";
    white-space: pre-wrap;
}

#scene_01 p::after {
    content: "";
    white-space: pre-wrap;
}

#scene_01 a {
    display: none !important;
    visibility: hidden;
}

.info {
    width: 560px;
}

button.btn {
    /*
    background-color: #002d33;
    width: 30px;
    padding: 4px 5px 6px 5px;
    align-content: center;*/
    width: 520px;
    color: #2e879e;
    position: absolute;
    border: hidden;
    background-color: transparent;
    align-content: end;
    text-align: right;
    border-radius: 20px;
    font-size: 24px;
    height: 36px;
}

    button.btn:hover {
        cursor: pointer;
        /*background-color: #2e879e;
        color: #002d33;*/
        transition: linear ease-in-out 0.4s !important;
    }

    button.btn:focus:not(:focus-visible) {
        outline: 0;
        border: hidden;
        outline-offset: 0px;
        cursor: pointer;
    }

    button.btn:focus-visible {
        outline: 0px;
        outline-offset: 0px;
    }

a.alink {
    color: #fbb954;
    display: block;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    align-items: center;
}

h4 {
    margin-top: 5px;
}

.answer {
    font-size: 14px;
    padding-top: 5px;
    transition-duration: 1s;
    color: #72b6cf;
}

.container h3 {
    border-bottom: 2px solid #2e879e;
    padding-bottom: 30px;
    margin-top: 20px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 5px;
    /*color: #fbb954;*/
    color: #72b6cf;
}

.Fire {
    height: 90px;
    position: absolute;
    bottom: 0;
}

#Fire {
    min-height: 90px;
}

#NftText, #scene_01, #scene_02 {
    /*padding: 40px;*/
}

@media all and (max-width:1920px) {
    #nftgrid {
        grid-area: col1 !important;
    }
}

@media all and (max-width:1000px) {
    #NftText {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        overflow: visible;
    }

    #NftText, #scene_01, #scene_02 {
        /*padding: 10px*/
    }
}

@media all and (max-width:500px) {
    .container, .info {
        width: 100vw;
        max-width: 100vw;
    }
}

@-webkit-keyframes AnimA {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }

    50% {
        opacity: 0.5;
        transform: translateY(-5px);
    }

    100% {
        visibility: hidden;
        opacity: 0;
        transform: translateY(-5px);
    }
}

@keyframes AnimA {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }

    50% {
        opacity: 0.5;
        transform: translateY(-5px);
    }

    100% {
        visibility: hidden;
        opacity: 0;
        transform: translateY(-5px);
    }
}

@-webkit-keyframes AnimB {
    0% {
        opacity: 0;
        transform: translateY(0px);
    }

    50% {
        opacity: 1;
        transform: translateY(10px);
    }

    100% {
        opacity: 0;
        transform: translateY(5px);
    }
}

@keyframes AnimB {
    0% {
        opacity: 0;
        transform: translateY(0px);
    }

    50% {
        opacity: 1;
        transform: translateY(10px);
    }

    100% {
        opacity: 0;
        transform: translateY(5px);
    }
}

@-webkit-keyframes AShow {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes AShow {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes AHide {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0.0;
    }
}

@keyframes AHide {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0.0;
    }
}
